import {viewItemList, selectItem, viewItem, addToCart, removeFromCart, viewCart, beginCheckout, addShippingInfo, addPaymentInfo, purchase, updateLoadDataLayer, chunksArray} from "@modules/dataLayer";

window.addEventListener('load', () => {
	const items = document.querySelectorAll('article.product, li.product, .product-card');

	const body = jQuery('body');

	chunksArray([...items]).forEach(products => {
		viewItemList(products);
	});

	body.on('avokado-finish-filter', function (event, data) {
		const items = [];
		for (const item in data) {
			const product = data[item];
			items.push(document?.querySelector(`.product-${product.id}`));
		}
		chunksArray([...items]).forEach(products => {
			viewItemList(products);
		});
	});

	document.addEventListener('click', e => {
		if (!e.target.closest('article.product a:not(.add_to_cart_button, .single_add_to_cart_button)') && !e.target.closest('li.product a:not(.add_to_cart_button, .single_add_to_cart_button)')) return;

		let link = e.target.closest('article.product');

		if (link === null) {
			link = e.target.closest('li.product');
		}

		selectItem(link);
	});

	const product = document?.querySelector('div.product');
	if (document.body.classList.contains('single-product')) {
		product && viewItem(product);
	}

	jQuery(document).on('found_variation', 'form.cart', function () {
		product && viewItem(product);
	});

	document.addEventListener('click', e => {
		if (!e.target.closest('.quick-view')) return;

		let product = e.target.closest('article.product');
		if (!product) {
			product = e.target.closest('li.product');
		}

		if (!product) {
			product = e.target.closest('.product-card');
		}

		selectItem(product);
		viewItem(product);
	});

	document.addEventListener('click', e => {
		if (!e.target.closest('.add_to_cart_button:not(.product_type_variable, .product_type_grouped, .single_add_to_cart_button)') && !e.target.closest('.single_add_to_cart_button')) return;

		addToCart(e.target.closest('.product'));
	});

	document.addEventListener('change', e => {
		if (!e.target.closest('.cart_item input')) return;

		const qtyEl = e.target.closest('.cart_item input'), originalValue = qtyEl.defaultValue;

		let currentValue = parseInt(qtyEl.value);

		if (originalValue !== currentValue && originalValue < currentValue) {
			addToCart(e.target.closest('.cart_item'));
		} else if (originalValue !== currentValue && originalValue > currentValue) {
			removeFromCart(e.target.closest('.cart_item'));
		}
	});

	document.addEventListener('click', e => {
		if (!e.target.closest('.cart_item .remove')) return;

		removeFromCart(e.target.closest('.cart_item'));
	});

	const isCart = document.body.classList.contains('woocommerce-cart'),
		isCheckout = document.body.classList.contains('woocommerce-checkout');

	let cartItems = document.querySelectorAll('div.woocommerce form.woocommerce-cart-form .cart_item');

	if (cartItems.length <= 0) {
		cartItems = document.querySelectorAll('.woocommerce-checkout-review-order-table .cart_item');
	}

	if (isCheckout && cartItems.length > 0) {
		viewCart(cartItems);
		beginCheckout(cartItems);
		addShippingInfo(cartItems);
		addPaymentInfo(cartItems);
	} else if (isCart && cartItems.length > 0) {
		viewCart(cartItems);
	}


	document.addEventListener('change', e => {
		if (!e.target.closest('input[name^=shipping_method]')) return;

		addShippingInfo(cartItems);
	});

	document.addEventListener('change', e => {
		if (!e.target.closest('input[name^=payment_method]')) return;

		addPaymentInfo(cartItems);
	});

	const woocommerceOrder = document.querySelector('.woocommerce-order');

	if (woocommerceOrder) {
		const loadedDataLayer = woocommerceOrder?.querySelector('[name="avokado_loaded_data_layer"]'),
			orderStatus = woocommerceOrder?.querySelector('[name="order_status"]'),
			dataLayerData = woocommerceOrder?.querySelector('[name="data_layer"]'),
			data = JSON.parse(dataLayerData.value);

		if (loadedDataLayer.value === '' && orderStatus.value === '') {
			purchase(data);
			updateLoadDataLayer(data['transaction_id']);
		}
	}
})
