/**
 * Trigger items for impression.
 * @param elements
 */
const viewItemList = (elements) => {
	const items = getViewItemList(elements);
	let dataLayerItems = [];

	items.filter(item => dataLayerItems.push(getProductData(item)));

	if (dataLayerItems.length > 0) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'view_item_list',
			ecommerce: {
				items: dataLayerItems
			}
		});
	}
}

/**
 * Trigger click on link for product and push data layer.
 * @param product
 */
const selectItem = (product) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'select_item', ecommerce: {
			items: [getProductData(product)]
		}
	});
}

/**
 * Trigger view product page and push data layer.
 * @param product
 */
const viewItem = (product) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'view_item', ecommerce: {
			value: getEccommerceData(product)?.value,
			currency: getEccommerceData(product).currency,
			items: [getProductData(product, false)]
		}
	});
}

/**
 * After add product to cart push data layer for that event
 * @param product
 */
const addToCart = (product) => {
	const quantity = product?.querySelector('input.qty'), quantityValue = quantity ? quantity.value : 1;

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'add_to_cart', ecommerce: {
			value: getEccommerceData(product)?.value * quantityValue,
			currency: getEccommerceData(product)?.currency,
			items: [getProductData(product)]
		}
	});
}

/**
 * Trigger event after remove product from cart.
 * @param product
 */
const removeFromCart = (product) => {
	const quantity = product?.querySelector('input.qty'), quantityValue = quantity ? quantity.value : 1;
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'remove_from_cart', ecommerce: {
			value: getEccommerceData(product)?.value * quantityValue,
			currency: getEccommerceData(product)?.currency,
			items: [getProductData(product, false)]
		}
	});
}

/**
 * Trigger view cart event on reload page.
 */
const viewCart = (items) => {
	let dataLayerItems = [], totalValue = [], currency = '';
	items.forEach(item => {
		dataLayerItems.push(getProductData(item, false));
		const quantity = item?.querySelector('input.qty'), quantityValue = quantity ? quantity.value : 1;
		totalValue.push(getEccommerceData(item)?.value * quantityValue);
		currency = getEccommerceData(item)?.currency;
	});

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'view_cart', ecommerce: {
			value: totalValue.reduce((a, b) => a + b),
			currency: currency,
			items: dataLayerItems
		}
	});
}

/**
 * Begin checkout trigger.
 */
const beginCheckout = (items) => {
	let dataLayerItems = [], totalValue = [], currency = '';
	items.forEach(item => {
		dataLayerItems.push(getProductData(item, false));
		const quantity = item?.querySelector('input.qty'), quantityValue = quantity ? quantity.value : 1;
		totalValue.push(getEccommerceData(item)?.value * quantityValue);
		currency = getEccommerceData(item)?.currency;
	});

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'begin_checkout', ecommerce: {
			value: totalValue.reduce((a, b) => a + b),
			currency: currency,
			items: dataLayerItems
		}
	});
}

/**
 * Push data layer for shipping info.
 * @param items
 */
const addShippingInfo = (items) => {
	let dataLayerItems = [], totalValue = [], currency = '';

	items.forEach(item => {
		dataLayerItems.push(getProductData(item, false));
		const quantity = item?.querySelector('input.qty'), quantityValue = quantity ? quantity.value : 1;
		totalValue.push(getEccommerceData(item)?.value * quantityValue);
		currency = getEccommerceData(item)?.currency;
	});

	let shipping_tier = '(shipping tier not found)';
	let shipping_el = document.querySelector('input[name^=shipping_method]:checked');
	if (!shipping_el) {
		shipping_el = document.querySelector('input[name^=shipping_method]'); // select the first input element
	}
	if (shipping_el) {
		shipping_tier = shipping_el.nextElementSibling.textContent.replace(/(<([^>]+)>)/ig, '');
		if (shipping_tier === '') {
			shipping_tier = shipping_el.nextElementSibling.nextElementSibling.textContent.replace(/(<([^>]+)>)/ig, '');
		}
	}

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'add_shipping_info', ecommerce: {
			value: totalValue.reduce((a, b) => a + b),
			currency: currency,
			shipping_tier: shipping_tier,
			items: dataLayerItems
		}
	});
}

/**
 * Push data layer for payment info.
 * @param items
 */
const addPaymentInfo = (items) => {
	let dataLayerItems = [], totalValue = [], currency = '';

	items.forEach(item => {
		dataLayerItems.push(getProductData(item, false));
		const quantity = item?.querySelector('input.qty'), quantityValue = quantity ? quantity.value : 1;
		totalValue.push(getEccommerceData(item)?.value * quantityValue);
		currency = getEccommerceData(item)?.currency;
	});

	let payment_type = '(payment type not found)';
	let payment_el = document.querySelector('.payment_methods input:checked');
	if (!payment_el) {
		payment_el = document.querySelector('input[name^=payment_method]'); // select the first input element
	}
	if (payment_el) {
		payment_type = payment_el.nextElementSibling.textContent.replace(/(<([^>]+)>)/ig, '');
		if (payment_type === '') {
			payment_type = payment_el.nextElementSibling.nextElementSibling.textContent.replace(/(<([^>]+)>)/ig, '');
		}
	}

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'add_payment_info', ecommerce: {
			value: totalValue.reduce((a, b) => a + b),
			currency: currency,
			payment_type: payment_type,
			items: dataLayerItems
		}
	});
}

/**
 * Trigger purchase data layer only once and only if order status is success
 */
const purchase = (dataLayer) => {

	let items = [];

	dataLayer['items'].forEach(item => items.push(prepareDataForLayer(null, item, null, null, false)));

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event: 'purchase', ecommerce: {
			currency: dataLayer['currency'],
			value: dataLayer['value'],
			tax: dataLayer['tax'],
			shipping: dataLayer['shipping'],
			affiliation: dataLayer['affiliation'],
			transaction_id: dataLayer['transaction_id'],
			coupon: dataLayer['coupon'] ? dataLayer['coupon'] : '',
			items: items
		}
	});
}

/**
 * Ajax update value to run purchase event only one.
 */
const updateLoadDataLayer = (order_id) => {
	jQuery.post(gtm4wpApiSettings.ajaxUrl, {
		order_id: order_id, action: 'avokado_update_load_data_layer', nonce: gtm4wpApiSettings.nonce
	}).fail(err => {
		console.error('Ajax error:', err);
	});
}

/**
 * Return array with all elements.
 * @returns {[]}
 */
const getViewItemList = (products) => {
	let items = [], existingIds = [];

	products?.forEach(item => {
		const grm4wpProductData = item?.querySelector('[name="gtm4wp_product_data"]'), productData = grm4wpProductData ? JSON.parse(grm4wpProductData?.value) : null, productId = productData ? productData['item_id'] : null, listId = productData ? productData['item_list_id'] : null;

		if (productId && listId && !existingIds.includes(productId + '-' + listId)) {
			items.push(item);
			existingIds.push(productId + '-' + listId);
		}
	});

	return items;
}

/**
 * Split all elements into multiple arrays with 20 elements.
 * @param array
 * @returns {*[]}
 */
const chunksArray = (array) => {
	const chunks = [];
	for (let i = 0; i < array.length; i += 20) {
		const chunk = array.slice(i, i + 20);
		chunks.push(chunk);
	}
	return chunks;
}

/**
 *
 * Get ecoomerce value and currency
 *
 * @param {*} product
 */
const getEccommerceData = (product) => {
	const grm4wpProductData = product?.querySelector('[name="gtm4wp_product_data"]'), productData = grm4wpProductData ? JSON.parse(grm4wpProductData?.value) : null;

	return {
		value: productData['value'],
		currency: productData['currency'],
	}
}

/**
 * Return data, current variation and current variation id.
 * @param product
 * @param showListData
 * @returns {{quantity: (*|number), item_id: any, price: any, item_name: any}}
 */
const getProductData = (product, showListData = true) => {
	try {
		const grm4wpProductData = product?.querySelector('[name="gtm4wp_product_data"]'), quantity = product?.querySelector('input.qty'), productData = JSON.parse(grm4wpProductData.value);

		let form = product?.querySelector('form.variations_form');

		if (!form) {
			form = product?.closest('form.variations_form');
		}

		const variationProductData = form && JSON.parse(form.dataset.product_variations), currentVariationID = form && form.querySelector('[name="variation_id"]').value;

		let activeVariation = false;

		for (const i in variationProductData) {
			const variation = variationProductData[i];
			if (parseInt(currentVariationID) === variation['variation_id']) {
				activeVariation = variation;
				break;
			}
		}

		return prepareDataForLayer(product, productData, quantity, activeVariation, showListData);
	} catch (e) {
		console.error(e);
	}
}

/**
 *
 * Prepare data for data layer in specific order.
 *
 * @param product
 * @param {*} productData
 * @param {*} quantity
 * @param {*} variation
 * @param {*} showListData
 * @returns
 */
const prepareDataForLayer = (product, productData, quantity = 1, variation = null, showListData = true) => {
	let data = {
		item_name: productData['item_name'],
		item_id: variation ? variation.variation_id : productData['item_id'],
		price: variation ? variation.display_price : productData['price']
	}

	let discountPrice = product?.querySelector('[name="avokado_discount_price"]');

	if(discountPrice) {
		data.price = discountPrice.value;
	}

	if (productData['item_brand'] !== '') {
		data.item_brand = productData['item_brand'];
	}

	if (productData['item_category'] !== '') {
		data.item_category = productData['item_category'];
	}

	if (productData['item_category2'] !== '') {
		data.item_category2 = productData['item_category2'];
	}

	if (productData['item_category3'] !== '') {
		data.item_category3 = productData['item_category3'];
	}

	if (productData['item_category4'] !== '') {
		data.item_category4 = productData['item_category4'];
	}

	if (variation && variation.attributes) {
		const attributes = [];

		for (const attribute_name in variation.attributes) {
			if (Object.prototype.hasOwnProperty.call(variation.attributes, attribute_name)) {
				attributes.push(variation.attributes[attribute_name]);
			}
		}

		data.item_variant = attributes.join(', ');
	} else if (productData['item_variant'] !== '') {
		data.item_variant = productData['item_variant'];
	}

	if (productData['google_business_vertical'] !== '') {
		data.google_business_vertical = productData['google_business_vertical'];
		data.id = productData['item_id'];
	}

	if (showListData) {
		data.item_list_name = productData['item_list_name'] ?? '';
		data.item_list_id = productData['item_list_id'] ?? '';
		data.index = productData['index'] ?? '';
	}
	data.quantity = quantity ? quantity.value : (productData['quantity'] ?? 1);

	return data;
}

export {
	viewItemList,
	selectItem,
	viewItem,
	addToCart,
	removeFromCart,
	viewCart,
	beginCheckout,
	addShippingInfo,
	addPaymentInfo,
	purchase,
	updateLoadDataLayer,
	chunksArray
}
